import React, { useState } from "react";
import { getToken } from "./SecureStorage/Token";
import { BASE_URL } from "../Url/constants";
import ErrorNotification from "../Notification/ErrorNotification";
import { NotificationContainer } from "react-notifications";
import "./CreateCustomer.css";
import { validateEmail, validatePhone } from "../Url/validationUtils";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { sendRequest } from "../ApiRequest";
import Copyrights from "./Copyrights";
import { useTranslation } from "react-i18next";
import successNotification from "../Notification/SuccessNotification";

const CreateCollapse = ({ handleToggleForm }) => {
  const [supplierData, setSupplierData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    supp_company: "",
    com_address: "",
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const token1 = getToken();
  const MAX_LENGTH = 64;
  const { t } = useTranslation();

  const handleSupplierChange = (e) => {
    const { name, value } = e.target;

    let newErrors = { ...errors };

    if (name === "phone_number") {
      const indianPhoneRegex = /^$|^[6-9]\d{0,9}$/;
  
      if (indianPhoneRegex.test(value)) {
        setSupplierData({ ...supplierData, [name]: value });
      }
      const phoneValidationResult = validatePhone(value);
      if (phoneValidationResult) {
        newErrors.phone_number = phoneValidationResult;
      } else {
        delete newErrors.phone_number;
      }
    } else {
      if (value.length <= MAX_LENGTH) {
        setSupplierData({ ...supplierData, [name]: value });
      }
      if (name === "email") {
        const emailValidationResult = validateEmail(value);
        if (emailValidationResult) {
          newErrors.email = emailValidationResult;
        } else {
          delete newErrors.email;
        }
      }
    }

    if (name === "first_name") {
      if (/[^A-Za-z]/.test(value)) {
        newErrors.first_name = "First Name must contain only letters.";
      } else {
        delete newErrors.first_name;
      }
    }

    if (name === "last_name") {
      if (/[^A-Za-z]/.test(value)) {
        newErrors.last_name = "Last Name must contain only letters.";
      } else {
        delete newErrors.last_name;
      }
    }

    if (name === "supp_company" && !value) {
      newErrors.supp_company = "Company Name is required.";
    } else {
      delete newErrors.supp_company;
    }

    if (name === "com_address" && !value) {
      newErrors.com_address = "Company Address is required.";
    } else {
      delete newErrors.com_address;
    }

    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    const newErrors = {};

    if (!supplierData.email) newErrors.email = "Email is required.";
    if (!supplierData.phone_number) newErrors.phone_number = "Phone number is required.";
    if (!supplierData.supp_company) newErrors.supp_company = "Company Name is required.";
    if (!supplierData.com_address) newErrors.com_address = "Company Address is required.";

    const emailValidationResult = validateEmail(supplierData.email);
    if (emailValidationResult) newErrors.email = emailValidationResult;

    const phoneValidationResult = validatePhone(supplierData.phone_number);
    if (phoneValidationResult) newErrors.phone_number = phoneValidationResult;

    if (/[^A-Za-z]/.test(supplierData.first_name)) {
      newErrors.first_name = "First Name must contain only letters.";
    }
    if (/[^A-Za-z]/.test(supplierData.last_name)) {
      newErrors.last_name = "Last Name must contain only letters.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const supplier_params = {
      supplier: {
        first_name: supplierData.first_name,
        last_name: supplierData.last_name,
        email: supplierData.email,
        phone_number: supplierData.phone_number,
        supp_company: supplierData.supp_company,
        com_address: supplierData.com_address,
      },
    };

    try {
      const response = await sendRequest("POST", `${BASE_URL}/v1/onboarding/suppliers`, supplier_params, token1);
      if (response.status === 200) {
        successNotification("Supplier created successfully");
        navigate("/Suppliers");
      }
    } catch (error) {
      ErrorNotification(error.data.errors[0]);
    }
  };

  const handleCancel = () => {
    navigate("/Suppliers");
  };

  return (
    <>
      <Header />
      <div className="content">
        <div className="col-md-12 margin-btm">
          <h3 className="title">{t("Add Supplier")}</h3>
        </div>
        <div className="create-company">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("First Name")}
                  name="first_name"
                  maxLength={30}
                  value={supplierData.first_name}
                  onChange={handleSupplierChange}
                />
                {errors.first_name && <div className="error-message">{errors.first_name}</div>}
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Last Name")}
                  name="last_name"
                  maxLength={30}
                  value={supplierData.last_name}
                  onChange={handleSupplierChange}
                />
                {errors.last_name && <div className="error-message">{errors.last_name}</div>}
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className={`form-control ${errors.email ? "required-field" : ""}`}
                  placeholder={t("Email*")}
                  name="email"
                  value={supplierData.email}
                  onChange={handleSupplierChange}
                />
                {errors.email && <div className="error-message">{errors.email}</div>}
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className={`form-control ${errors.phone_number ? "required-field" : ""}`}
                  placeholder={t("Phone No*")}
                  name="phone_number"
                  maxLength={12}
                  value={supplierData.phone_number}
                  onChange={handleSupplierChange}
                />
                {errors.phone_number && <div className="error-message">{errors.phone_number}</div>}
              </div>

              <div className="col-md-6">
                <input
                  type="text"
                  className={`form-control ${errors.supp_company ? "required-field" : ""}`}
                  placeholder={t("Company Name*")}
                  name="supp_company"
                  value={supplierData.supp_company}
                  onChange={handleSupplierChange}
                  maxLength={MAX_LENGTH}
                />
                {errors.supp_company && <div className="error-message">{errors.supp_company}</div>}
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className={`form-control ${errors.com_address ? "required-field" : ""}`}
                  placeholder={t("Company Address*")}
                  name="com_address"
                  value={supplierData.com_address}
                  onChange={handleSupplierChange}
                  maxLength={MAX_LENGTH}
                />
                {errors.com_address && <div className="error-message">{errors.com_address}</div>}
              </div>
              <div className="col-md-12">
                <button className="btn btn-primary save-btn-1" type="submit">
                  {t("Save")}
                </button>
                <button className="btn btn-primary" type="button" onClick={handleCancel}>
                  {t("Cancel")}
                </button>
              </div>
            </div>
          </form>
          <NotificationContainer />
        </div>
      </div>
      <Copyrights />
    </>
  );
};

export default CreateCollapse;
