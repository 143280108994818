import React, { useEffect, useState } from 'react';
import "./TableReservation.css";
import { sendRequest } from '../../../ApiRequest';
import { useNavigate } from 'react-router-dom';
import CommonCloseButton from '../CommonCloseButton/CommonCloseButton';
import { BASE_URL } from '../../../Url/constants';
import { useTranslation } from "react-i18next";
import ErrorNotification from '../../../Notification/ErrorNotification';
import { NotificationContainer } from 'react-notifications';

function TableReservation({ onClose, tableId, tableData, tableStatus }) {
  const paxNumbers = Array.from({ length: 20 }, (_, i) => i + 1);
  const [captains, setCaptains] = useState([]);
  const [selectedPax, setSelectedPax] = useState(null);
  const [selectedCaptain, setSelectedCaptain] = useState(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const navigate = useNavigate();
  const { t} = useTranslation();
    
  useEffect(() => {
    const fetchCaptains = async () => {
      try {
        const response = await sendRequest('GET', `${BASE_URL}/v1/staff_management/staffs`);
        const staffData = response.data.data.filter(
          (staff) => staff.role_details.role_name === "staff"
        );
        const captainsList = staffData.map(staff => ({
          id: staff.id,
          name: `${staff.first_name} ${staff.last_name}`
        }));
        
        setCaptains(captainsList);
      } catch (error) {
        error.data.errors.map(error=> ErrorNotification(error));
      }
    };

    fetchCaptains();
  }, []);

  const handleCrossClick = () => {
    setShowAnimation(true);
    setTimeout(() => {
      setShowAnimation(false);
      onClose();
    });
  };

  const handlePaxClick = (pax) => {
    setSelectedPax(pax);
  };

  const handleCaptainClick = (captain) => {
    setSelectedCaptain(captain);
    const orderType = 'dine_in'
    navigate('/kot-create', {
      state: {
        orderType,
        tableId,
        tableData,
        selectedPax,
        captionName: captain.name,
        selectedCaptainId: captain.id,
        tableStatus: tableStatus
      }
    });
  };

  return (
    <div className="modal-overlay">
      <NotificationContainer/>
      <div className="pax-selector-container">
        <div className='pax-heading-title'>
          <span className='heading-select-pax-captain-table'>{t("Select Pax And Captain For Table")} {tableId}</span>
          <span className="pax-close-icon" onClick={handleCrossClick}>
            <CommonCloseButton />
          </span>
        </div>

        <div className={`main-content ${showAnimation ? 'animate' : ''}`}>
          <div className="pax-selection">
            <h3>{t("SELECT PAX")}</h3>
            <div className="pax-grid">
              {paxNumbers.map(pax => (
                <button key={pax} className={`pax-button ${selectedPax === pax ? 'selected' : ''}`} onClick={() => handlePaxClick(pax)}>
                  {pax}
                </button>
              ))}
            </div>
          </div>
          <div className="captain-selection">
            <h3>{t("SELECT CAPTAIN")}</h3>
            <div className="captain-grid">
              {captains.map((captain, index) => (
                <button key={index} className={`captain-button ${selectedCaptain?.id === captain.id ? 'selected' : ''}`} onClick={() => handleCaptainClick(captain)}>
                  {captain.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableReservation;
