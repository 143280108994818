import React, { useState, useEffect } from "react";
import Header from "../Header";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./ExpanseBill.css";
import Copyrights from "../Copyrights";
import Search from "../Search/Search.jsx";
import { NotificationContainer } from "react-notifications";
import { getToken } from "../SecureStorage/Token.jsx";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import PaginationControls from "../../components/Common/PaginationControls";
import EntriesPerPageDropdown from "../../components/Common/EntriesPerPageDropdown";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../Url/constants.js";
import { sendRequest } from "../../ApiRequest.js";


const CustomTable = ({ col_names, data }) => {

  // const openFile = (url) => {
  //   window.open(url, "_blank");
  // };
  return (
    <table className="custom-table">
      <thead>
        <tr>
          {col_names.map((col, index) => (
            <th key={index}>{col}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {Object.entries(row).map(([key, cell], cellIndex) => (
              <td key={cellIndex}>
                {key === "View File" && cell ? (
                  <a href={cell} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFile} className="file-icon" />
                  </a>
                ) : (
                  cell
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

function ExpanseBill() {
  const [totalPages] = useState(1);
  const [totalRecords] = useState(0);

  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setrecordPerPage] = useState(10);

  const [searchQuery, setSearchQuery] = useState("");
  const token = getToken();
  const endIndex = Math.min(currentPage * recordPerPage, totalRecords);
  const { t } = useTranslation();


  const paginate = async (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {

        const response = await sendRequest(
          'GET',
          `${BASE_URL}/v1/setting/expanses`,
          null
        );
        const data = response.data;
        console.log("Fetched data:", data);
        if (isMounted) {
          if (Array.isArray(data.data)) {
            setItems(data.data);
          } else if (data.data && typeof data.data === "object") {
            setItems([data.data]);
          } else {
            console.error("Unexpected data format:", data);
          }
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching expenses data:", error);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [token]);

  const startIndex = (currentPage - 1) * recordPerPage + 1;

  const handleSearch = (query) => {
    if (query.length >= 2) {
      setSearchQuery(query);
    } else {
      setSearchQuery("");
      setCurrentPage(1);
    }
  };

  const handleRecordPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    setrecordPerPage(perPage);
    setCurrentPage(1);
  };

  const filteredItems = Array.isArray(items)
    ? items.filter((item) =>
      item.name?.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : [];

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="col d-flex justify-content-between align-items-center">
          <div>
            <h3 className="heading-title">{t("All Expenses")} ({items.length})</h3>
          </div>
          <div>
            <Link to="/expanse-data" className="btn btn-primary mt-3">
              <FontAwesomeIcon icon={faPlus} className="plus-icon" />
              <span>{t("Add Expense")}</span>
            </Link>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="pagination-dropdown">
              <div className="pagination-controls">
                <EntriesPerPageDropdown
                  recordPerPage={recordPerPage}
                  handleRecordPerPageChange={handleRecordPerPageChange}
                />
              </div>
              <div
                className="text-right"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
              >
                <Search placeholder={t("Search Expense Bill ...")} />
              </div>
            </div>
          </div>

          <div className="table-view">
            <CustomTable
              col_names={[
                t("S. No."),
                t("Expense Name"),
                t("Description"),
                t("Amount"),
                t("View File"),
                t("Status"),
              ]}
              data={filteredItems.map((item, index) => {
                return {
                  "S. No.": startIndex + index,
                  "Expense Name": item.name,
                  Description: item.description,
                  Amount: item.amount,
                  "View File": item.file,
                  Status: "pending",
                };
              })}
            />
            {filteredItems.length === 0 && searchQuery.length > 1 && (
              <div className="no-report-data-message">{t("No match found")}</div>
            )}
            {filteredItems.length === 0 && searchQuery.length === 0 && (
              <div className="no-report-data-message">{t("No records found")}</div>
            )}
          </div>
          {totalRecords >= recordPerPage && (
            <div className="report-pagination-info">
              <div className="report-pagination-container">
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                  totalRecords={totalRecords}
                  startIndex={startIndex}
                  endIndex={endIndex}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Copyrights />
      <NotificationContainer />
    </>
  );
}

export default ExpanseBill;
