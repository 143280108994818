import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendRequest } from '../../ApiRequest';
import { BASE_URL } from '../../Url/constants';
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";

const ExpanseData = () => {
  const [expanse, setExpanse] = useState({
    name: '',
    description: '',
    amount: '',
    file: '',
  });
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'amount' && (!/^\d*$/.test(value) || value.length > 7)) {
      return;
    }
    setExpanse({
      ...expanse,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setExpanse({
      ...expanse,
      [name]: files[0]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = 'your-auth-token';
    const formData = new FormData();
    formData.append('expanses[name]', expanse.name);
    formData.append('expanses[description]', expanse.description);
    formData.append('expanses[amount]', expanse.amount);
    formData.append('expanses[file]', expanse.file);

    try {
      const response = await sendRequest('POST', `${BASE_URL}/v1/setting/expanses`, formData, token);
      if (response.status === 200) {
        navigate('/expanse-bill');
      }
    } catch (error) {
      console.error(error);
      alert(error.response.data.errors[0]);
    }
  };

  return (
    <>
      <Header />
      <div className="content">
        <div className="col-md-12 margin-btm">
          <h3 className="title">{t("Add Expense")}</h3>
        </div>
        <div className="create-company">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Name")}
                  name="name"
                  value={expanse.name}
                  onChange={handleChange}
                  maxLength={30}
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Description")}
                  name="description"
                  value={expanse.description}
                  onChange={handleChange}
                  maxLength={50}
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Amount")}
                  name="amount"
                  value={expanse.amount}
                  onChange={handleChange}
                  maxLength={7}
                  required
                />
              </div>

              <div className="col-md-6">
                <input
                  type="file"
                  className="form-control"
                  placeholder={t("Upload File")}
                  name="file"
                  onChange={handleFileChange}
                  required
                />
              </div>
              <div className="col-md-12">
                <button className="btn btn-primary save-btn-1" type="submit">
                  {t("Save")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ExpanseData;
