// export const BASE_URL = process.env.REACT_APP_REMOTE_URL;
export const BASE_URL = process.env.REACT_APP_LOCAL_URL;

export const months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' }
  ];
  
export const ERROR_MESSAGE = (error_type) => {
  return `failed to create ${error_type}, please try again.`
}
export const UPDATE_ERROR_MSG = (update_error_type) => {
  return `failed to update ${update_error_type}, please try again.`
}
export const NO_RECORD_ERROR = (no_record_typr) => {
  return `no record found for ${no_record_typr}.`
}
export const INPUT_PLACEHOLDER = () => {
  return `Search By Customer Email Or Phone`
}

export const WENTWRONG = () => {
  return `Something went wrong, try again later.`
}

export const SUCCESS_MESSAGE = (type, value) => {
  return `${value} ${type} Successfully.`
}

export const statusLabels = {
  'Draft': 0,
  'Success': 1,
  'Failed': 2,
  'Pending': 3
};


export const paymentModes = {
  'cash': 0,
  'online': 1,
  'card': 2,
  'multi_payment': 3
}

export const DURATION_OPTIONS = {
  TODAY: 'TODAY',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
  CUSTOM: 'CUSTOM',
};
